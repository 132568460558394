import { RoomItem } from '../items';
import { type COLIRoom } from '../rooms';
import { SortDirection } from '../utils';

export interface CustomerOrderLineItem {
  id: number;
  item: RoomItem;
  room: COLIRoom;
  includedInPriceList: boolean;
  retailPriceInPriceList: number;
  inHomePriceOfPriceList: string;
  inHomePriceWithDiscountOfPriceList: number;
  renewalIntension: string;
  discountPercentageOfPriceList: number;
  priceListItemId: number;
  priceListItemStatus: string;
  createdAt: string;
}

export const COLIS_PER_PRICE_PAGE = 10;

export type ColiSortKeys = 'included';

export type ColiSortParamsType = ColiSortParams<ColiSortKeys>;

export interface ColiSortParams<K extends string = string> {
  orderBy: K;
  direction: SortDirection;
}

export interface CustomerOrderLineItemForTrackingTab {
  id: number;
  itemId: number;
  itemSku: string;
  itemName: string;
  itemTypeId: number;
  roomId: number;
  itemThumbnail: string;
  createdAt: string;
}
