import React, { useMemo } from 'react';
import {
  useQuery,
  type QueryClient,
  type UseQueryOptions,
  type QueryFunction,
} from 'react-query';

import { type SkipFirst } from 'js/types';
import {
  http,
  Query,
  type QueryProps,
  type HttpBaseConfig,
  type Project,
} from 'js/api';
import { CustomerOrderLineItemForTrackingTab } from '../models';

/**
 * GET - /api/v1/eames/customer_order_line_items/for_tracking_tab
 */

// HTTP

export type GetCustomerOrderLineItemsForTrackingTabXhr =
  CustomerOrderLineItemForTrackingTab[];

export interface GetCustomerOrderLineItemsForTrackingTabXhrParams {
  projectId: Project['id'];
  withoutOtli?: boolean;
}

export interface GetCustomerOrderLineItemsForTrackingTabXhrConfig
  extends HttpBaseConfig {
  params: GetCustomerOrderLineItemsForTrackingTabXhrParams | null;
}

export const getCustomerOrderLineItemsForTrackingTabXhr = (
  config: GetCustomerOrderLineItemsForTrackingTabXhrConfig
): Promise<GetCustomerOrderLineItemsForTrackingTabXhr> =>
  http
    .get<GetCustomerOrderLineItemsForTrackingTabXhr>(
      `/api/v1/eames/customer_order_line_items/for_tracking_tab`,
      config
    )
    .then((res) => res.data);

// Query Key

const GET_CUSTOMER_ORDER_LINE_ITEMS_FOR_TRACKING_TAB =
  'customerOrderLineItemsForTrackingTab';

type GetCustomerOrderLineItemsForTrackingTabQueryKeyNamespace =
  typeof GET_CUSTOMER_ORDER_LINE_ITEMS_FOR_TRACKING_TAB;

export type GetCustomerOrderLineItemsForTrackingTabQueryKey = [
  GetCustomerOrderLineItemsForTrackingTabQueryKeyNamespace,
  GetCustomerOrderLineItemsForTrackingTabXhrParams
];

export const createGetCustomerOrderLineItemsForTrackingTabQueryKey = (
  key: SkipFirst<GetCustomerOrderLineItemsForTrackingTabQueryKey>
): GetCustomerOrderLineItemsForTrackingTabQueryKey => [
  GET_CUSTOMER_ORDER_LINE_ITEMS_FOR_TRACKING_TAB,
  ...key,
];

// QueryFn

export const getCustomerOrderLineItemsForTrackingTabQueryFn: QueryFunction<
  GetCustomerOrderLineItemsForTrackingTabXhr,
  GetCustomerOrderLineItemsForTrackingTabQueryKey
> = ({ queryKey: [_d, params], signal }) =>
  getCustomerOrderLineItemsForTrackingTabXhr({ params, signal });

// create Query Options

export type GetCustomerOrderLineItemsForTrackingTabQueryOptions =
  UseQueryOptions<
    GetCustomerOrderLineItemsForTrackingTabXhr,
    unknown,
    GetCustomerOrderLineItemsForTrackingTabXhr,
    GetCustomerOrderLineItemsForTrackingTabQueryKey
  >;

export interface GetCustomerOrderLineItemsForTrackingTabQueryOptionsProps {
  params: GetCustomerOrderLineItemsForTrackingTabXhrParams;
  options?: Omit<
    GetCustomerOrderLineItemsForTrackingTabQueryOptions,
    'queryFn' | 'queryKey'
  >;
}

export const createGetCustomerOrderLineItemsForTrackingTabQueryOptions = ({
  options,
  params,
}: GetCustomerOrderLineItemsForTrackingTabQueryOptionsProps): GetCustomerOrderLineItemsForTrackingTabQueryOptions => ({
  queryFn: getCustomerOrderLineItemsForTrackingTabQueryFn,
  queryKey: createGetCustomerOrderLineItemsForTrackingTabQueryKey([params]),
  ...options,
});

// Hook

export interface UseGetCustomerOrderLineItemsForTrackingTabQueryProps {
  options?: GetCustomerOrderLineItemsForTrackingTabQueryProps['options'];
  params: GetCustomerOrderLineItemsForTrackingTabXhrParams;
}

export const useGetCustomerOrderLineItemsForTrackingTabQuery = ({
  params,
  options,
}: UseGetCustomerOrderLineItemsForTrackingTabQueryProps) => {
  const queryKey = useMemo(
    () => createGetCustomerOrderLineItemsForTrackingTabQueryKey([params]),
    [params]
  );

  return useQuery(
    queryKey,
    getCustomerOrderLineItemsForTrackingTabQueryFn,
    options
  );
};

// Query

export interface GetCustomerOrderLineItemsForTrackingTabQueryProps
  extends Omit<
    QueryProps<
      GetCustomerOrderLineItemsForTrackingTabXhr,
      Error,
      GetCustomerOrderLineItemsForTrackingTabXhr,
      GetCustomerOrderLineItemsForTrackingTabQueryKey
    >,
    'queryFn' | 'queryKey'
  > {
  params: GetCustomerOrderLineItemsForTrackingTabXhrParams;
}

export type GetCustomerOrderLineItemsForTrackingTabQueryResult = Parameters<
  GetCustomerOrderLineItemsForTrackingTabQueryProps['children']
>[0];

export const getCustomerOrderLineItemsForTrackingTabQuery = ({
  params,
  ...props
}: GetCustomerOrderLineItemsForTrackingTabQueryProps) => {
  return (
    <Query
      {...props}
      queryKey={createGetCustomerOrderLineItemsForTrackingTabQueryKey([params])}
      queryFn={getCustomerOrderLineItemsForTrackingTabQueryFn}
    />
  );
};

// Query Cache Helpers

export const invalidateGetCustomerOrderLineItemsForTrackingTabQueryData =
  async (
    client: QueryClient,
    keyParams: SkipFirst<GetCustomerOrderLineItemsForTrackingTabQueryKey>
  ) => {
    await client.invalidateQueries(
      createGetCustomerOrderLineItemsForTrackingTabQueryKey(keyParams)
    );
  };

export const invalidateAllGetCustomerOrderLineItemsForTrackingTabQueryData =
  async (client: QueryClient) => {
    await client.resetQueries(GET_CUSTOMER_ORDER_LINE_ITEMS_FOR_TRACKING_TAB);
  };
